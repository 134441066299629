import Model from './Model'

export default class VenueSubmission extends Model {
    // Set the resource route of the model
    resource() {
        return 'venue-submissions'
    }

    confirm() {
        return this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/venue-submissions/${this.id}/confirm`,
                data: this
            }, { forceMethod: true })
        ).then(response => {
            return this._applyInstance(response.data.data || response.data)
        })
    }

    reject() {
        return this.request(
            this._reqConfig({
                method: 'GET',
                url: `/api/venue-submissions/${this.id}/reject`,
                data: this
            }, { forceMethod: true })
        ).then(response => {
            return this._applyInstance(response.data.data || response.data)
        })
    }
}

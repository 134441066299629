<template>
    <dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="venueSubmissions"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.status="{ item }">
                {{ getStatusString(item.status) }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
            </template>
            <template v-slot:item.actions="{ item }">
                <div v-if="item.status === 0">
                    <v-btn color="green" @click="confirm(item)"> confirm</v-btn>
                    <v-btn color="red" @click="reject(item)"> reject</v-btn>
                </div>
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import VenueSubmission from "@/models/VenueSubmission";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "Index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            venueSubmissions: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Submission made by', value: 'user.name'},
                {text: 'City', value: 'city.name'},
                {text: 'Address', value: 'address'},
                {text: 'Status', value: 'status'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    async mounted() {
        this.loading = true
        this.venueSubmissions = await VenueSubmission.with(['user', 'city']).get()
        this.loading = false
    },
    methods: {
        getStatusString(status) {
            switch (status) {
                case 0:
                    return 'Pending'
                case 1:
                    return 'Approved'
                case 2:
                    return 'Rejected'
                default:
                    return 'error'
            }
        },
        async confirm(venueSubmission) {
            venueSubmission.status = (await venueSubmission.confirm()).status
        },
        async reject(venueSubmission) {
            venueSubmission.status = (await venueSubmission.reject()).status
        },
    }
}
</script>

<style scoped>

</style>
